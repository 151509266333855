<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :backgroundImage="'customs-info'"
          class="clear-550"
      >
        <template slot="body">
          <div class="section-label large font-weight-bold">
            {{$t('common_SelectCountry.localization_value.value')}}
          </div>
          <div class="customs-info__search-input">
            <DefaultSelect
                :options="options"
                :label="$t('common_SelectCountryYouNeed.localization_value.value')"
                :optionsLabel="'label'"
                @change="changeCountry"
                :selected="filterCountryName"
            />
          </div>
        </template>
      </CardLeftBlock>
    </div>
    <div class="detail-page__right">
      <CustomsInfoList
          @showMore="showMore"
          @reload="reload"
      />
    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CustomsInfoList from "./CustomsInfoList/CustomsInfoList";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "CustomInfo",

    components: {
      DefaultSelect,
      CardLeftBlock,
      CustomsInfoList,
    },

    mixins: [routeFilter],

    data() {
      return {
        date: null,
        isEditPopup: false,
        editNotificationId: '',

        input: '',

        forPage: this.$store.getters.getCustomLimitsForPage,
        page: 1,
        onePage: 1,
        filterCountryId: '',
        filterCountryName: '',

        countFilterParams: 0,
        filterGetParams: {},

        options: [],
      }
    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        this.options = this._.toArray(this.$store.getters.getCountries)
      })

      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          countryId: this.$route.query.countryId,
          countryName: this.$route.query.countryName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextCustomLimitsPage', true)
        this.$store.dispatch('fetchCustomLimits', url).then(() => {
          this.$store.commit('setNextCustomLimitsPage', false)
        })


        this.checkCountFilter(['id'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
            myQuery,
            {
              filterCountryId: 'DeliveryCountryCustomCountryId',
            },
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      changeCountry(val) {

        this.changeFilter({
          countryId: val !== '' ? val.id : '',
          countryName: val !== '' ? val.label : ''
        })
      },

    }
  }
</script>

<style lang="scss" scoped>
  .customs-info__search-input{
    max-width: 306px;
  }


</style>
