import {checkValid} from "../../../globalModels/functions/ModelsValidate";

export function CustomsInfo() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    country: '',
    textEn: '',
    textUa: '',
    textRu: '',
  }

  this.validation = {
    id: false,
    textEn: false,
    textUa: false,
    textRu: false,
  }

  this.validationTranslate = {
    id: '',
    textEn: '',
    textUa: '',
    textRu: '',
  }


  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getTextEn = () => {
    return this.data.textEn
  }
  this.getCountry = () => {
    return this.data.country
  }
  this.getTextRu = () => {
    return this.data.textRu
  }
  this.getTextUa = () => {
    return this.data.textUa
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setTextEn = (val) => {
    this.data.textEn = val
  }
  this.setTextUa = (val) => {
    this.data.textUa = val
  }
  this.setTextRu = (val) => {
    this.data.textRu = val
  }
  this.setCountry = (val) => {
    this.data.country = val
  }
}

/**
 * Global Setters
 */


CustomsInfo.prototype.setItem = function(item) {
  this.setId(item?.id)
  this.setCountry(item?.country)
  this.setTextEn(item?.customs_info_en)
  this.setTextUa(item?.customs_info_ua)
  this.setTextRu(item?.customs_info_ru)
}

/**
 * Functions
 */

CustomsInfo.prototype.prepareData = function () {
  return {
    country_id: this.getCountry()?.id,
    customs_info_en: this.getTextEn(),
    customs_info_ua: this.getTextUa(),
    customs_info_ru: this.getTextRu(),
  }
}