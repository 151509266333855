var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup guide-edit-popup",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'guide_guideEdit',
        ])}}}),_vm._v(" "+_vm._s(_vm.$t('guide_guideEdit.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content"},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'guide_textEng',
        ])}}}),_c('div',{staticClass:"mb-2 fsz14",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('guide_textEng.localization_value.value')))]),_c('TextEditor',{attrs:{"value":_vm.customsInfo.data.textEn},on:{"input":_vm.inputTextEditorEn}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'guide_textUa',
        ])}}}),_c('div',{staticClass:"mb-2 fsz14",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('guide_textUa.localization_value.value')))]),_c('TextEditor',{attrs:{"value":_vm.customsInfo.data.textUa},on:{"input":_vm.inputTextEditorUa}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'guide_textRu',
        ])}}}),_c('div',{staticClass:"mb-2 fsz14",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('guide_textRu.localization_value.value')))]),_c('TextEditor',{attrs:{"value":_vm.customsInfo.data.textRu},on:{"input":_vm.inputTextEditorRu}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"custom-popup__btn align-items-center d-flex flex-wrap mt-5"},[_c('span',{staticClass:"site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"custom-popup__btn-i",attrs:{"value":_vm.$t('common_save.localization_value.value')},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }