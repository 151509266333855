<template>
  <modal
      @close="$emit('close')"
      class="custom-popup guide-edit-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'guide_guideEdit',
          ])"></div>
        {{ $t('guide_guideEdit.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="custom-row">
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_textEng',
          ])"></div>
            <div style="font-weight: bold;" class="mb-2 fsz14">{{$t('guide_textEng.localization_value.value')}}</div>
            <TextEditor
                :value="customsInfo.data.textEn"
                @input="inputTextEditorEn"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_textUa',
          ])"></div>
            <div style="font-weight: bold;" class="mb-2 fsz14">{{$t('guide_textUa.localization_value.value')}}</div>
            <TextEditor
                :value="customsInfo.data.textUa"
                @input="inputTextEditorUa"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_textRu',
          ])"></div>
            <div style="font-weight: bold;" class="mb-2 fsz14">{{$t('guide_textRu.localization_value.value')}}</div>
            <TextEditor
                :value="customsInfo.data.textRu"
                @input="inputTextEditorRu"
            />
          </div>

        </div>

      </div>

    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap mt-5">
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
        {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
            :value="$t('common_save.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/commonModals/Modal";
import TextEditor from "@/components/coreComponents/TextEditor/TextEditor";
import {CustomsInfo} from "@/components/modules/CustomsInfoModule/models/CustomsInfo";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
export default {
  name: "EditCustomsInfoPopup",
  components: {MainButton, TextEditor, Modal},

  props: {
    dataCustoms: Object,
  },

  data() {
    return {
      customsInfo: new CustomsInfo(),
    }
  },

  mounted() {
    this.customsInfo.setItem(this.dataCustoms)
  },

  methods: {
    inputTextEditorEn(val) {
      this.customsInfo.setTextEn(val)
    },
    inputTextEditorRu(val) {
      this.customsInfo.setTextRu(val)
    },
    inputTextEditorUa(val) {
      this.customsInfo.setTextUa(val)
    },

    save() {
      let saveType = 'updateCustomLimits'

      let data = {
        id: this.customsInfo.getId(),
        data: this.customsInfo.prepareData()
      }

      console.log(data);
      console.log(saveType);

      return this.$store.dispatch(saveType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            this.openNotify('success', 'common_notificationRecordChanged')
            this.$emit('reload')
            this.$emit('close')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    }
  },

}
</script>

<style scoped>

</style>